@for (panel of panels; track panel) {
  <div class="lc-card lc-icon-card" [attr.id]="panelID(panel)">
    <div><img class="lc-icon" [src]="panel.iconURL" alt="" /></div>
    <div class="lc-flex-container">
      <h3 class="lc-title">{{ panel.title }}</h3>
      <td-rich-text [value]="panel.body" class="flex" />
      @if (panel.link) {
        <a class="link-tertiary lc-cta-weak" [tdLink]="panel.link">{{ panel.link.text }}</a>
      }
    </div>
  </div>
}

<div class="ftc-root" [ngClass]="{ 'ftc-unchecked': booleanValue === false }">
  @if (booleanValue === true) {
    <i class="fa-regular fa-check"></i>
  }
  @if (stringValue) {
    <span>{{ stringValue }}</span>
  }
  @if (buttonValue) {
    <td-button [button]="buttonValue" buttonWidth="100%" [linkId]="buttonId()"/>
  }
</div>

@if (button.comingSoon && !noComingSoonTooltip) {
  <td-tooltip text="Coming soon!" [visible]="comingSoonPopupVisible"/>
}

<a
  tabindex="0" class="text-p2"
  [ngClass]="rootNgClass"
  [attr.id]="linkId"
  [attr.role]="isDisabled ? 'button' : undefined"
  [attr.aria-disabled]="isDisabled || undefined"
  [tdLink]="linkButton && !linkButton.comingSoon ? linkButton.link : undefined"
  (click)="button.onClick ? button.onClick() : undefined"
  (keydown)="button.onClick ? button.onClick() : undefined"
  [attr.download]="linkButton?.download ? linkButton!.download!.filename || '' : undefined"
  [ngStyle]="{ width: buttonWidth }"
  (mouseenter)="onMouseEnter($event)"
  (mouseleave)="onMouseLeave($event)"
  >
  <span>{{ button.text }}</span>
</a>

<div class="tb-main">
  <td-heading-with-highlights [value]="block.title" [id]="block.sectionId" [themeColorHex]="themeColorHex" [level]="level"/>
  <div></div>
  <div>
    @if (block.body && !noBody) {
      <td-rich-text [value]="block.body" class="text-p1" />
    }
    @if (block.actions) {
      <td-actions [sectionId]="block.sectionId" [actions]="block.actions" />
    }
    @if (organisationLogos?.length) {
      <td-organisation-logos appearance="transparent" [organisations]="organisationLogos!"/>
    }
  </div>
</div>

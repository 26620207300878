<article nebula="cloud">
  @if (page$ | async; as page) {
    <div class="article-content">
      <td-title-body-actions-section
        [isIntroSection]="true" sectionId="intro"
        [title]="page.introSection.title" [body]="page.introSection.body" [actions]="page.introSection.actions"
        />
      @for (section of page.coreSections; track section; let idx = $index) {
        <td-generic-page-technicolor-block [section]="section" [index]="idx" />
        <td-content-panel [panel]="section.panel" [panelId]="'core-section-' + idx" />
      }
      <td-features-page-technicolor-block [section]="page.finalSection" [index]="page.coreSections.length" />
      <td-conclusion-panel [panel]="page.finalSection.panel" />
    </div>
  }
</article>
